<template>
  <div>
    <p class="p-text-title">Inventario</p>
    <skeleton-inventory-component v-if="bSkeleton" />

    <div v-else class="content-quantity-raw">
      <div class="content-title-text">
        <p class="p-title">Cantidad actual de la materia</p>
        <p class="p-text">{{ formatMoneyGlobal(iCurrentAmount) }} Kg</p>
      </div>
      <div class="content-btns-actions">
        <div class="content-btn-new-quantity">
          <v-btn
            class="global-btn-primary"
            v-show="bAdminMaterial"
            @click="setDialogNewQuantity"
          >
            Actualizar cantidad
          </v-btn>
        </div>
      </div>
    </div>

    <v-divider class="divider-global mt-9"></v-divider>
    <dialog-new-quantity-component
      :bDialogNewQuantity="bDialogNewQuantity"
      :iCurrentAmount="iCurrentAmount"
      @setDialogNewQuantity="setDialogNewQuantity"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      oInventory: {},
      bSkeleton: true,
      iCurrentAmount: 0,
      options: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      bDialogNewQuantity: false,
    };
  },
  beforeMount() {
    this.getInventoryById();
  },
  methods: {
    setDialogNewQuantity() {
      this.bDialogNewQuantity = !this.bDialogNewQuantity;
    },
    getInventoryById() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/inventory`,
        config,
        payload
      )
        .then((response) => {
          this.oInventory = response.data.results;
          this.iCurrentAmount = response.data.results.dInventory;
          this.bSkeleton = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    refreshTable: function () {
      this.getInventoryById();
    },
  },
};
</script>
<style scoped>
.content-quantity-raw {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}
.p-title {
  color: var(--primary-color-text-title-detail);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: start;
}
.p-text {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 35px;
  text-align: start;
  margin-bottom: 0px;
}

.content-title-text {
  margin-bottom: 15px;
  width: 100%;
}

.content-btns-actions {
  display: flex;
  justify-content: end;
  align-self: center;

  margin-top: 15px;
}
.content-btn-close {
  width: 200px;
  margin-right: 15px;
}
.content-btn-new-quantity {
  width: 170px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-quantity-raw {
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }

  .p-text-title {
    text-align: center;
  }
  .p-title {
    text-align: center;
  }
  .p-text {
    text-align: center;
  }
  .content-btn-new-quantity {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>